<template>
  <div class="add-bank">
    <Header />

    <b-container class="add-bank-container">
      <b-row class="justify-content-md-start back-nav">
        <b-link to="/profile/payment-account"
          ><b-icon class="mx-2" icon="chevron-left"></b-icon
          ><span>Kembali</span></b-link
        >
      </b-row>
      <b-row class="justify-content-center">
        <div>
          <b-card-group deck>
            <b-card>
              <h1 class="pb-4">Tambah Rekening Bank</h1>
              <template>
                <div>
                  <b-form @submit.prevent="handleSubmit">
                    <b-row class="justify-content-md-start mb-4">
                      <div class="col-lg-12 col-md-6">
                        <h3>Nama Pemilik Rekening</h3>
                        <vs-input
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="account_name"
                          :value="user.individual.ifua_name"
                          required
                        />
                        <ul class="ml-3 my-2">
                          <li>
                            Pemilik rekening harus sama dengan yang didaftarkan
                            di CGS iTrade Fund.
                          </li>
                          <li>
                            Penulisan nama pemilik rekening sama dengan yang
                            terdaftar di bank.
                          </li>
                        </ul>
                      </div>

                      <div class="col-lg-12 col-md-6 py-2">
                        <h3>Nama Bank</h3>
                        <vs-select
                          autocomplete
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="bank_id"
                          name="bank_id"
                          id="bank_id"
                          style="width: 100%"
                          required
                        >
                          <vs-select-item
                            :key="index"
                            :value="bank.id"
                            :text="bank.bank_name"
                            v-for="(bank, index) in banks"
                          />
                        </vs-select>
                        <div class="error mt-3" v-if="!$v.bank_id.required">
                          <p>Wajib diisi</p>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-6 py-2">
                        <h3>Nomor Rekening</h3>
                        <vs-input
                          class="mb-2 mr-sm-2 mb-sm-0"
                          type="number"
                          v-model="account_number"
                          required
                        />
                        <div
                          class="error mt-3"
                          v-if="!$v.account_number.required"
                        >
                          <p>Wajib diisi</p>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-6 py-2">
                        <h3>Mata Uang</h3>
                        <b-row>
                          <div class="col-lg-6 col-md-6">
                            <vs-radio
                              v-model="currency_id"
                              vs-name="currency_id"
                              vs-value="1"
                              >IDR: Indonesia Rupiah</vs-radio
                            >
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <vs-radio
                              v-model="currency_id"
                              vs-name="currency_id"
                              vs-value="2"
                              >USD: US Dollar</vs-radio
                            >
                          </div>
                        </b-row>
                        <div class="error mt-3" v-if="!$v.currency_id.required">
                          <p>Wajib diisi</p>
                        </div>
                      </div>
                    </b-row>
                    <b-row class="justify-content-end btn-submit-row mb-4">
                      <div class="col-12 text-right">
                        <b-button
                          block
                          type="submit"
                          :disabled="
                            loading ||
                            !account_number ||
                            !account_name ||
                            !bank_id ||
                            !currency_id
                          "
                          @click.once="handleSubmit()"
                          class="btn-tertiary py-2 px-4"
                        >
                          <div v-if="loading">
                            <div class="spinner-border spinner-border-sm"></div>
                            Loading
                          </div>
                          <div v-else>Simpan</div>
                        </b-button>
                      </div>
                    </b-row>
                  </b-form>

                  <div class="modal-add-bank">
                    <b-modal
                      id="modal-success-add-bank"
                      v-model="successAddBank"
                      size="md"
                      class="modal-success-add-bank"
                      centered
                      hide-footer
                      hide-header
                      no-close-on-backdrop
                    >
                      <div class="d-block text-center">
                        <img
                          class="pt-4"
                          src="@/assets/img/icons/transactions/checked-blue.svg"
                          alt=""
                        />
                        <h1 class="px-4 my-2 text-dark">
                          Rekening Berhasil Disimpan
                        </h1>
                        <p class="text-muted">
                          Rekening akan diverifikasi dan bisa digunakan setelah
                          1-2 hari.
                        </p>
                        <b-row
                          class="justify-content-md-end btn-submit-row mb-4"
                        >
                          <div class="col-lg-12 col-md-6">
                            <b-button
                              size="sm"
                              block
                              class="btn-tertiary px-4 py-2"
                              href="/profile/payment-account"
                              >Lihat Daftar Rekening</b-button
                            >
                          </div>
                        </b-row>
                      </div>
                    </b-modal>
                  </div>

                  <div class="modal-add-bank">
                    <b-modal
                      id="modal-success-add-bank"
                      v-model="failedAddBank"
                      size="md"
                      class="modal-success-add-bank"
                      centered
                      hide-footer
                      no-close-on-backdrop
                    >
                      <div class="d-block text-center">
                        <img src="@/assets/img/ekyc/failed.svg" alt="" />
                        <h1 class="p-4 my-2 text-dark">
                          Ups! <br />
                          Terjadi kesahan.
                        </h1>
                        <b-row class="justify-content-center">
                          <b-button
                            href="https://api.whatsapp.com/send?phone=6287777936468"
                            block
                            class="mt-3 mx-2 btn-tertiary py-2"
                            >Hubungi Customer Care</b-button
                          >
                        </b-row>
                      </div>
                    </b-modal>
                  </div>
                </div>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-row>
    </b-container>
    <div class="register-form">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";

import axios from "axios";
import { mapGetters } from "vuex";

import { required } from "vuelidate/lib/validators";

import instance from "@/utils/api";

export default {
  name: "AddAccount",
  metaInfo: {
    title: "Tambah Nomor Rekening | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
  validations: {
    bank_id: {
      required,
    },
    account_number: {
      required,
    },
    currency_id: {
      required,
    },
  },
  data() {
    return {
      banks: [],
      bank: [],
      bank_id: null,
      account_number: "",
      currency_id: "",
      account_name: "",
      individual_id: "",

      successAddBank: false,
      failedAddBank: false,
      loading: false,
      disabled: false,
      processing: false,
    };
  },
  mounted() {
    this.fetchBanks();
  },
  created() {
    this.account_name = this.user.individual.ifua_name;
    this.individual_id = this.user.individul.id;
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      check: "auth/check",
      user: "auth/user",
      access_token: "auth/access_token",
    }),
  },
  methods: {
    async fetchBanks() {
      let response = await axios({
        method: "GET",
        url: "banks",
        headers: {},
      });
      this.banks = response.data.data;
    },
    handleSubmit() {
      if (!this.processing) {
        this.processing = true;
        let formData = new FormData();
        formData.append("bank_id", this.bank_id);
        formData.append("account_number", this.account_number);
        formData.append("currency_id", this.currency_id);
        formData.append("account_name", this.account_name);
        formData.append("individual_id", this.individual_id);
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        };

        instance
          .post("individual-account/add-bank-account", formData, axiosConfig)
          .then((res) => {
            console.log("AXIOS SUCCESS: ", res);
            this.loading = !false;
            this.successAddBank = true;

            this.processing = false;
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.failedAddBank = true;

            this.processing = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-bank {
  background: #f3f4f6;
}
.add-bank-container {
  padding-top: 140px;
  padding-bottom: 80px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #18181b;
  text-align: center;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #18181b;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4f566b;
}

li {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #374151;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #e53e3e;
}

.vs-con-input-label {
  width: 100%;
}

.con-vs-checkbox {
  justify-content: start;
  margin: 0;
}

.login-label {
  padding-top: 16px;
}

.forgot-password-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #6b7280;
  text-decoration: none;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.back-nav {
  padding: 0px 240px 20px 308px;

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.remember-me {
  padding-top: 16px;
  padding-bottom: 16px;
}

.custom-control-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #3c4257;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  padding: 48px;
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
  width: 500px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

.dont-have-account {
  padding-top: 28px;
}

.sign-up-link {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #e60012;
  text-decoration: none;
}

.licensed {
  padding-top: 30px;
}

.license-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1f2937;
}

.ojk-logo-image {
  width: 120px;
}

.modal-login {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: black;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #4b5563;
  }
}

@media only screen and (max-width: 768px) {
  .login-container {
    padding-bottom: 60px;
  }

  .back-nav {
    padding: 0px 240px 20px 8px;
  }
  .card-body {
    width: 100%;
  }
  .forgot-password-link {
    font-size: 10px;
  }
  .dont-have-account-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .licensed {
    text-align: center;
  }
  .ojk-logo-image {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1366px) {
  .login-container {
    padding-bottom: 300px;
  }
}
</style>